const actionName = {
  LOADING: "loading",
  UPDATE_SET_PASSWORD_VAL: "UPDATE_SET_PASSWORD_VAL",
  UPDATE_OTP_VAL: "update_otp_val",
  UPDATE_COUNTRY_VAL: "update_country_val",
  UPDATE_CONFIRM_MODAL_VAL: "UPDATE_confirm_modal_VAL",
  UPDATE_THEME_VALUE: "UPDATE_THEME_VALUE",
  SIGN_IN: "SIGN_IN",
  PHARMACY_CITY: "PHARMACY_CITY",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  ADD_MEDICINE: "ADD_MEDICINE",
  CREATE_PROFILE_DATA: "CREATE_PROFILE_DATA",
  GET_INSURANCE_COMPANY: "GET_INSURANCE_COMPANY",
  SENT_CLAIMS: "SENT_CLAIMS",
  INSURANCE_PATIENT_PROFILE: "INSURANCE_PATIENT_PROFILE",
  GET_PATIENT_DATA: "GET_PATIENT_DATA",
  MEDICINE_DETAILS: "MEDICINE_DETAILS",
  CREATE_MEDICINE_DETAILS_LIST: "CREATE_MEDICINE_DETAILS_LIST",
  DELETE_MEDICINE_DETAILS_LIST: "DELETE_MEDICINE_DETAILS_LIST",
  CLEAR_MEDICINE_DETAILS_LIST: "CLEAR_MEDICINE_DETAILS_LIST",
  MYCLAIM_SEARCH_MEDICINE_DOCTOR_HOSPITAL: "MYCLAIM_SEARCH_MEDICINE_DOCTOR_HOSPITAL",
  ADD_MEDICINE_FORMIK: "ADD_MEDICINE_FORMIK",
  CREATE_CLAIM_SECOND_SETP: "CREATE_CLAIM_SECOND_SETP",
  CLAIM_DETAIL: "CLAIM_DETAIL",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  PAYMENT_METHODS: "PAYMENT_METHODS",
  GET_TEMPLATE_CLAIMS: "GET_TEMPLATE_CLAIMS",
  MY_PROFILE_PERSONAL_INFO: "MY_PROFILE_PERSONAL_INFO",
  EDIT_MY_PROFILE_PERSONAL_INFO: "EDIT_MY_PROFILE_PERSONAL_INFO",
  GET_DASHBOARD: "GET_DASHBOARD",
  SENT_CLAIM_DETAIL: "SENT_CLAIM_DETAIL",
  DOCTOR_PRESCRIPTIONS: "DOCTOR_PRESCRIPTIONS",
  HOSPITAL_PRESCRIPTIONS: "HOSPITAL_PRESCRIPTIONS",
  DRUG_FORMULARY: "DRUG_FORMULARY",
  GET_WORK_TIME: "GET_WORK_TIME",
  ADD_MEDICINE_FORMIK_PRESCRIPTION: "ADD_MEDICINE_FORMIK_PRESCRIPTION",
  CREATE_MEDICINE_DETAILS_LIST_PRESCRIPTION: "CREATE_MEDICINE_DETAILS_LIST_PRESCRIPTION",
  DELETE_MEDICINE_DETAILS_LIST_PRESCRIPTION: "DELETE_MEDICINE_DETAILS_LIST_PRESCRIPTION",
  CLEAR_MEDICINE_DETAILS_LIST_PRESCRIPTION: "CLEAR_MEDICINE_DETAILS_LIST_PRESCRIPTION",
  CREATE_CLAIM_PRESCRIPTION: "CREATE_CLAIM_PRESCRIPTION",
  CREATE_CLAIM_SECOND_SETP_DOCTOR: "CREATE_CLAIM_SECOND_SETP_DOCTOR",
  GET_SIGNUP_CREATE_PROFILE_DETAILS: "GET_SIGNUP_CREATE_PROFILE_DETAILS",
  Tab_DETAILS: "TAB_DETAILS",
  GET_TERMS_DETAILS: "GET_TERMS_DETAILS",
  GET_EXPORT: "GET_EXPORT",
  DOCTOR_HOSPITAL_ICD10_DETAILS: "DOCTOR_HOSPITAL_ICD10_DETAILS",
  INSURANCE_PAYMENT: "INSURANCE_PAYMENT",
  WEBSITE_MANAGEMENT: "WEBSITE_MANAGEMENT",
  RESET_INSURANCE_PATIENT_PROFILE: "RESET_INSURANCE_PATIENT_PROFILE",
  GET_PROVIDER_DETAILS: "GET_PROVIDER_DETAILS",
  GET_SUBSCRIPTION_DETAILS: "GET_SUBSCRIPTION_DETAILS",
  SUBSCRIPTION_HISTORY: "SUBSCRIPTION_HISTORY",
  SUBSCRIPTION_STATUS: "SUBSCRIPTION_STATUS",
};

export default actionName;
