// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
// import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Button, TextField, Typography, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { LoadingButton } from "@mui/lab";
import {
  getPhoneIdFromLS,
  getUserIdFromLS,
  getFrUniqueIdInLS,
} from "../../utils/commonFunctions";
import InsuranceService from "../../service/InsuranceCompany/InsuranceCompanyService";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { onSubmitFaceId } from "../../screens/myClaims/action";
import Utils from "../../utils";
import CustomModal from "../modal/customModal";

const FR = (props) => {
  const {
    face_id,
    insuranceStatus,
    entity_id,
    patientName,
    phone_no,
    insuranceCompanyName,
    insurance_company_id,
    principle_entity_id,
    setIsStream,
    insuranceNumber,
    registerFaceID,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isResendApiLoading, setIsResendApiLoading] = useState(false);
  const [isOccupied, setOccupied] = useState(false);
  const [isOccupiedMsg, setOccupiedMsg] = useState("");
  const [showUpdateFaceId, setShowUpdateFaceId] = useState(false);
  const ws = useRef(null);
  const [myStream, setMyStream] = useState(null);
  const myVideo = useRef();
  const userVideo = useRef(null);
  const pc = useRef(null);
  const [loading, setLoading] = useState(false);

  const [
    showConfirmationPopupBeforeUpdate,
    setShowConfirmationPopupBeforeUpdate,
  ] = useState(false);

  const [userStream, setUserStream] = useState(null);
  const [imageCaptured, setImageCaptured] = useState(null);
  const [updatefaceId, setUpdatefaceId] = useState(null);
  const [showFaceNotCapturedPopup, setShowFaceNotCapturedPopup] =
    useState(false);
  const [showFaceCapturedPopup, setShowFaceCapturedPopup] = useState(false);
  const [noDevice, setNoDeviceOpen] = useState(false);
  const [noDeviceMsg, setNoDeviceOpenMsg] = useState("");
  const [rawImage, setRawImage] = useState(null);
  const [hideFaceIdBtn, setHideFaceIdBtn] = useState(true);
  const [showProceedBtn, setShowProceedBtn] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  const [isBlackScreen, setIsBlackScreen] = useState(false);
  const [noFrUniqueId, setNoFrUniqueId] = useState(false);
  const [videoNotShowing, setVideoNotShowing] = useState(false);
  const { id } = useSelector(
    (state: ReducersModal) => state.claimDetailReducer
  );
  const insuranceService = new InsuranceService();
  const socketURL = process.env.REACT_APP_SOCKET_URL;

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect when we update it
  }, [timeLeft]);

  const connectToSocket = () => {
    ws.current = io(socketURL, {
      // extraHeaders: {
      // 	Authorization: `networkId=${Ip}`,
      // },
      transports: ["websocket", "polling"],
      // upgrade: false,
      query: `type=browser&deviceId=&userId=${getUserIdFromLS()}&frUniqueId=${getFrUniqueIdInLS()}`,
    });
    console.log(ws.current.connected, "*******connecting to Socket******");
    console.log("current socket out", ws?.current);
    ws.current.on("connect", () => {
      console.log("current socket in", ws?.current);
      console.log(ws.current.connected, "*******connected******");
      // setSocketRef(ws.current);

      ws.current.on("message", (message) => {
        console.log(message, "message");
        if (message === "got user media") {
          maybeStart();
          if (message?.id) {
            myVideo.current.srcObject = message;
          }
        } else if (message.type === "offer") {
          console.log('message.type', message.type)
          if (!isInitiator && !isStarted) {
            maybeStart();
          }
          pc?.current?.setRemoteDescription(new RTCSessionDescription(message));
          doAnswer();
        } else if (message.type === "answer" && isStarted) {
          pc.current.setRemoteDescription(new RTCSessionDescription(message));
        } else if (message.type === "candidate" && isStarted) {
          var candidate = new RTCIceCandidate({
            sdpMLineIndex: message.label,
            candidate: message.candidate,
          });
          console.log("candidate", "call", isStarted);
          pc.current.addIceCandidate(candidate);

          setHideFaceIdBtn(true);
        } else if (message === "bye" && isStarted) {
          handleRemoteHangup();
          setUserStream(null);
          setIsStream(null);

        } else if (message === "detectedIdleState") {
          console.log("detectedIdleState");
          handleRemoteHangup();
          connectToSocket()
          setUserStream(null);
          setIsStream(null);

        }
      });

      if (room !== "") {
        ws.current.emit("create or join", room);
        console.log("Attempted to create or  join room", room);
      }

      ws.current.on("join", function (room) {
        console.log("Another peer made a request to join room " + room);
        console.log("This peer is the initiator of room " + room + "!");
        isChannelReady = true;
        setHideFaceIdBtn(false);
      });
      ws.current.on("deviceOccupied", (res) => {
        console.log("deviceOccupied", res);
        setOccupied(true);
        setOccupiedMsg(res?.msg);

        setHideFaceIdBtn(false);
      });
      ws.current.on("deviceNowFree", (res) => {
        console.log("deviceNowFree", res);
        setOccupied(true);
        setOccupiedMsg(res?.msg);
      });
      ws.current.on("sameUserWithOtherTabExists", function (res) {
        setShowAlreadyStream(res);
      });

      ws.current.on("joined", function (room) {
        console.log("joined: " + room);
        isChannelReady = true;
        isInitiator = true;
        setLoading(false);
        setHideFaceIdBtn(false);
      });
      ws.current.on("created", function (room) {
        console.log("Created room " + room);
        isInitiator = true;
        isChannelReady = true;
        setHideFaceIdBtn(false);
      });

      ws.current.on("captureImageResponse", (res) => {
        
        console.log("captureImageResponse ", res);
        // isInitiatorc = true;
        // setUserStream(null);
        // isStarted = false;
        setLoading(false);
        setHideFaceIdBtn(true);
        setTimeLeft(5);
        setImageCaptured(res);
        setIsBlackScreen(false);
        setVideoNotShowing(false);
        if (res.status === false || !res?.faceId)
          setShowFaceNotCapturedPopup(true);
        else if (res.status === true) setShowFaceCapturedPopup(true);
      });

      ws.current.on("playVideoOnAdminResponse", (res) => {
        setTimeLeft(0);
        setLoading(false);
        setNoDeviceOpen(true);
        setNoDeviceOpenMsg(res?.msg);
      });
      ws.current.on("updateFaceIdResponse", (res) => {
        setLoading(false);
        setUpdatefaceId(res);

        // setImageCaptured(res);
      });
      ws.current.on("deviceHandshakeOut", (reason) => {
        console.log("deviceHandshakeOut", reason);
        setNoDeviceOpen(true);
        if (reason.start) {
          setNoDeviceOpenMsg("Device is not associated with this network");
        }
        // setHideFaceIdBtn(false);
      });

      ws.current.on("startVideoRequestCallback", (res) => {
        console.log("startVideoRequestCallback", res?.msg);
        // setLoading(false);
        setNoDeviceOpen(true);
        setNoDeviceOpenMsg(res?.msg);
      });
      ws?.current?.on("rawImageResponse", (res) => {
        console.log(res, "rawImageResponse");
        setRawImage(res);
        setHideFaceIdBtn(true);
        setVideoNotShowing(false);
      });
      ws?.current?.on("connectionSocketId", (res) => {
        console.log(res, "connectionSocketId");
      });
    });

    ws.current.on("disconnect", (reason) => {
      setHideFaceIdBtn(true);
      console.log("current disconnect");
      setTimeout(() => {
        if (navigator.onLine && getFrUniqueIdInLS()) connectToSocket();
      }, 1000);
      console.log(
        ws.current.connected,
        `*******${reason}****** will attempt to reconnect in 1sec`
      );
    });
    ws.current.on("connect_error", (err) => {
      console.log(err, "*******connect_error******");
      ws.current.close();
    });
  };

  // useEffect(() => {
  // 	setTimeout(() => {
  // 		console.log("current connecttosocket call", !ws.current?.connected);
  // 		// if (Ip) connectToSocket();
  // 		if (getFrUniqueIdInLS() && !ws?.current?.connected) connectToSocket();
  // 	}, 1500);
  // }, [navigator.onLine]);
  var room = getFrUniqueIdInLS();

  useEffect(() => {
    console.log("is socket connected?", ws.current?.connected);
    if (
      navigator.onLine &&
      getFrUniqueIdInLS() &&
      !ws.current?.connected &&
      hideFaceIdBtn
    ) {
      console.log("connecttosocket called - useEffect 1");
      // setLoading(true);
      connectToSocket();
    }
  }, [hideFaceIdBtn]);

  useEffect(() => {
    if (!getFrUniqueIdInLS()) {
      setNoFrUniqueId(true);
    }

    return () => {
      ws?.current?.emit("onRefreshFromBrowser", "");
      ws?.current?.disconnect();
      // pc?.current?.setRemoteDescription(null);
      resetAllVar();
      ws?.current?.off("connect");
      ws?.current?.off("message");
      ws?.current?.off("join");
      ws?.current?.off("joined");
      ws?.current?.off("created");
      ws?.current?.off("captureImageResponse");
      ws?.current?.off("playVideoOnAdminResponse");
      ws?.current?.off("updateFaceIdResponse");
      ws?.current?.off("deviceHandshakeOut");
      ws?.current?.off("startVideoRequestCallback");
      ws?.current?.off("rawImageResponse");
      ws?.current?.off("connectionSocketId");
      setIsBlackScreen(false);
      setVideoNotShowing(false);
      setShowProceedBtn(false);
    };
  }, []);

  useEffect(() => {
    if (!entity_id) {
      resetAllVar();
    }
  }, [entity_id]);

  var vid = document.getElementById("myVideo");
  useEffect(() => {
    console.log(vid?.currentTime, "video current time");
    console.log(vid, "video tag");
    setTimeout(() => {
      if (!vid?.currentTime && vid) {
        setIsBlackScreen(true);
        setTimeLeft(15);
      }
    }, 3000);
  }, [vid]);
  useEffect(() => {
    if (isBlackScreen && timeLeft === 6) {
      setLoading(true);
      ws.current.emit("captureImageRequest", {
        start: 1,
        type: face_id ? "match" : "create",
        entityId: entity_id,
      });
    } else if (videoNotShowing && timeLeft === 6) {
      setLoading(true);
      ws.current.emit("captureImageRequest", {
        start: 1,
        type: face_id ? "match" : "create",
        entityId: entity_id,
      });
    }
  }, [isBlackScreen, videoNotShowing, timeLeft, face_id]);

  const resetAllVar = () => {
    isStarted = false;
    isInitiator = false;
    isChannelReady = false;
    // setShowImage(null);
    setUserStream(null);
    setImageCaptured(null);
    setUpdatefaceId(null);
    setShowFaceNotCapturedPopup(null);
    setShowFaceCapturedPopup(null);
    setNoDeviceOpen(false);
    setRawImage(null);
    setHideFaceIdBtn(true);
    setShowProceedBtn(false);
  };

  // let pc;
  var isStarted = false;
  var isInitiator = false;
  var isChannelReady = false;

  function handleRemoteStreamAdded(event: any) {
    setHideFaceIdBtn(true); //was false
    setLoading(false);
    console.log("Remote stream added", event.stream, userVideo?.current);
    setUserStream(event.stream);
    setIsStream(event.stream);
    setVideoNotShowing(false);
    setTimeout(() => {
      userVideo.current.srcObject = event.stream;
    }, 1000);
    userVideo?.current?.scrollIntoView();
  }

  function doCall() {
    console.log("Send1ing offer to peer");
    pc.current.createOffer(setLocalAndSendMessage, handleCreateOfferError);
  }
  function maybeStart() {
    console.log("maybeStart", isStarted + ' myStream ' + myStream + ' isChannelReady ' + isChannelReady + '  isInitiator ' + isInitiator);
    if (!isStarted && typeof myStream !== "undefined" && isChannelReady) {
      console.log("maybeStart in createPeerConnection", "call");
      createPeerConnection();
      // pc?.current?.addStream(myStream);
      isStarted = true;
      if (isInitiator) {
        doCall();
      }
    }
  }
  function setLocalAndSendMessage(sessionDescription) {
    pc?.current.setLocalDescription(sessionDescription);
    console.log("setLocalAndSendMessage sending message", sessionDescription);
    sendMessage(sessionDescription);
  }
  function handleCreateOfferError(event) {
    console.log("createOffer() error: ", event);
  }

  function handleNoDevice() {
    if (noDeviceMsg === "Device is not associated with this network") {
      resetAllVar();
      setLoading(true);
      console.log("connecttosocket called - handshake");
      connectToSocket();
    } else {
      setLoading(false);
      setNoDeviceOpen(false);
      setHideFaceIdBtn(false);
      setShowFaceCapturedPopup(false);
      setUserStream(null);
      setIsStream(null);
      setRawImage(null);
      setUpdatefaceId(null);
      setOccupied(false);
    }
  }
  function handleIceCandidate(event) {
    console.log("icecandidate event: ", event);
    if (event.candidate) {
      sendMessage({
        type: "candidate",
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
        // netWorkId: Ip,
        // deviceId: null,
        // conType: "browser",
      });
    } else {
      console.log("End of candidates.");
    }
  }
  function createPeerConnection() {
    try {
      pc.current = new RTCPeerConnection();
      pc.current.onicecandidate = handleIceCandidate;
      pc.current.onaddstream = handleRemoteStreamAdded;
      pc.current.onremovestream = handleRemoteStreamRemoved;
      pc.current.ontrack = (event) => console.log("ontrack", event);
      console.log("Created RTCPeerConnnection", pc);
    } catch (e) {
      console.log("Failed to create PeerConnection, exception: " + e.message);
      alert("Cannot create RTCPeerConnection object.");
      return;
    }
  }
  function sendMessage(message = "hi") {
    console.log("Client sending message: ", message);
    ws.current.emit("message", message);
  }

  function handleRemoteStreamRemoved(event) {
    console.log("Remote stream removed. Event: ", event);
  }

  function handleRemoteHangup() {
    console.log("Session terminated.");
    stop();

    // isInitiator = false;
    setHideFaceIdBtn(true);
  }
  function stop() {
    console.log("stop");
    isStarted = false;
    pc.current = null;
  }

  function doAnswer() {
    console.log("Sending answer to peer.");
    pc?.current
      ?.createAnswer()
      .then(setLocalAndSendMessage, onCreateSessionDescriptionError);
  }
  function onCreateSessionDescriptionError(error) {
    console.log("Failed to create session description: " + error.toString());
  }
  // phone_no.substring(3, 0)
  const sendOtp = () => {
    insuranceService.verifyInsurance(
      {
        phone_code: phone_no?.length > 10 ? phone_no.substring(0, 3) : "254",
        phone_number: phone_no?.length > 10 ? phone_no.substring(3) : phone_no,
        phone_id: getPhoneIdFromLS(),
        user_role: "4",
        os_type: 3,
        insurance_company_id: insurance_company_id,
      },
      (res) => {
        toast.success("OTP sent successfully.", {
          toastId: "1sd",
          className: "toast-success",
        });
        // setShowConfirmationPopupBeforeUpdate(false);
        setIsResendApiLoading(false);
      },
      (err) => {
        toast.error("Something went wrong!", {
          toastId: "2sd",
          className: "toast-error",
        });
        setIsResendApiLoading(false);
      }
    );
  };
  const verifyOtp = () => {
    setIsResendApiLoading(true);
    insuranceService.verifyOtp(
      {
        phone_code: phone_no?.length > 10 ? phone_no.substring(0, 3) : "254",
        phone_number: phone_no?.length > 10 ? phone_no.substring(3) : phone_no,
        phone_id: getPhoneIdFromLS(),
        user_role: "4",
        os_type: 3,
        auth_code: otp,
        entity_id: principle_entity_id ? principle_entity_id : entity_id,
        insurance_company_id: insurance_company_id,
        face_id: imageCaptured?.faceId,
        beneficiary: entity_id,
      },
      (res) => {
        setUpdatefaceId({ msg: "Face biometric updated sucessfully" });
        setShowConfirmationPopupBeforeUpdate(false);
        setIsResendApiLoading(false);
        ws?.current?.emit("FaceBiometricUpdated", {
          faceId: imageCaptured?.faceId,
          entityId: entity_id,
        });
      },
      (err) => {
        const { data } = err.response;
        setIsResendApiLoading(false);
        toast.error(data.messages[0], {
          toastId: "sd",
          className: "toast-error",
        });
      }
    );
  };
  return (
    <>
      {userStream && !rawImage ? (
        <div
          className="rightContainer"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2 style={{ textAlign: "center", margin: "5px 0" }}>
            Ask the patient to stand in front of the tab
          </h2>
          <h3 style={{ textAlign: "center", margin: "5px 0" }}>
            Kindly proceed to capture the face image to whom {insuranceNumber}{" "}
            belongs
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // flexDirection: "column",
            }}
          >
            <video
              id="myVideo"
              ref={userVideo}
              autoPlay
              muted
              style={{
                width: 880,
                height: 480,
                objectFit: "contain",
                background: "black",
                transform: "rotateY(180deg)",
                webkitTransform: "rotateY(180deg)",
                mozTransform: "rotateY(180deg)",
              }}
            />

            <LoadingButton
              loading={loading || (isBlackScreen && timeLeft)}
              loadingPosition="start"
              variant="contained"
              color="primary"
              className={`capitalize color-white`}
              sx={{ width: "200px", margin: "20px", padding: "auto 20px" }}
              onClick={() => {
                setLoading(true);
                ws.current.emit("captureImageRequest", {
                  start: 1,
                  type: face_id ? "match" : "create",
                  entityId: entity_id,
                });
              }}
              disabled={isBlackScreen && timeLeft}
            >
              {isBlackScreen && timeLeft
                ? `Capturing Face Biometric in ${timeLeft}s`
                : "Capture Face Biometric"}
            </LoadingButton>
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {rawImage ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={"data:image/png;base64, " + rawImage?.img}
              style={{ height: 480 }}
              alt=""
            />
            {!imageCaptured?.faceId && (
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                variant="contained"
                color="primary"
                className={`capitalize color-white`}
                sx={{ width: "240px", margin: "20px" }}
                onClick={() => {
                  setLoading(true);
                  ws.current.emit("captureImageRequest", {
                    start: 1,
                    type: face_id ? "match" : "create",
                    entityId: entity_id,
                  });
                }}
              >
                Capture Face Biometric
              </LoadingButton>
            )}
            {imageCaptured?.faceId && !face_id && (
              <div style={{ display: "flex", margin: "20px" }}>
                <Button
                  variant="contained"
                  className={`capitalize color-white ${loading ? "on-load" : ""
                    }`}
                  sx={{ width: "140px", margin: "0px" }}
                  onClick={() => {
                    if (!phone_no) {
                      toast.error("Please enter user Phone no", {
                        toastId: "dshf",
                        className: "toast-error",
                      });
                      return;
                    }
                    if (phone_no.length === 10) {
                      let validPhone = phone_no.charAt(0);
                      let validPhoneAt1 = phone_no.charAt(1);
                      if (
                        validPhone !== "0" ||
                        (validPhoneAt1 !== "7" && validPhoneAt1 !== "1")
                      ) {
                        toast.error("Invalid phone no", {
                          toastId: "dshf",
                          className: "toast-error",
                        });

                        return false;
                      } else {
                        dispatch({
                          type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                          payload: {
                            phoneNumber: phone_no.substring(1),
                          },
                        });
                        // phone_no = phone_no.substring(1);
                      }
                    } else if (phone_no.length === 9) {
                      let validPhoneAt1 = phone_no.charAt(0);
                      if (validPhoneAt1 !== "7" && validPhoneAt1 !== "1") {
                        toast.error("Invalid phone no", {
                          toastId: "dshf",
                          className: "toast-error",
                        });
                        return false;
                      }
                    } else {
                      toast.error("Invalid phone no", {
                        toastId: "dshf",
                        className: "toast-error",
                      });
                      return false;
                    }
                    setShowConfirmationPopupBeforeUpdate(true);
                    sendOtp();

                    // setLoading(true);
                    // ws?.current?.emit("updateFaceId", {
                    // 	entityId: entity_id,
                    // 	faceId: imageCaptured?.faceId,
                    // });
                  }}
                >
                  Accept
                </Button>
                <LoadingButton
                  loading={loading || timeLeft}
                  loadingPosition="start"
                  variant="contained"
                  color="primary"
                  className={`capitalize color-white`}
                  sx={{ width: "140px", marginLeft: "20px" }}
                  onClick={() => {
                    setLoading(true);
                    connectToSocket();
                    setRawImage(null);
                    // setTimeout(() => {
                    //   setRawImage(null);
                    //   ws?.current?.emit("startVideoRequest", {
                    //     start: 1,
                    //   });
                    // }, 0);
                  }}
                  disabled={!imageCaptured?.faceId || timeLeft}
                >
                  {timeLeft ? `Redo (${timeLeft}s)` : "Redo"}
                </LoadingButton>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {showUpdateFaceId && (
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="contained"
            color="primary"
            className={`capitalize color-white`}
            sx={{ width: "300px", margin: "10px 0" }}
            onClick={() => {
              // setShowConfirmationPopupBeforeUpdate(true);
              ws?.current?.emit("updateFaceId", {
                entityId: entity_id,
                faceId: imageCaptured?.faceId,
              });
            }}
          >
            Update Face biometric
          </LoadingButton>
        )}

        {!ws?.current?.connected && hideFaceIdBtn && !showProceedBtn && (
          <div>
            <CircularProgress color="primary" />
            <p>Connecting to Server...</p>
          </div>
        )}

        {insuranceStatus && !hideFaceIdBtn && !showProceedBtn && (
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="contained"
            color="primary"
            className={`capitalize color-white`}
            sx={{ width: "400px" }}
            onClick={() => {
              setLoading(true);
              setImageCaptured(null);
              if (ws.current.connected) {
                ws?.current?.emit("startVideoRequest", {
                  start: 1,
                });
                setVideoNotShowing(true);
                setTimeLeft(15);
              }
              // ws?.current?.emit("playVideoOnDeviceRequestCallback", {
              // 	start: 1,
              // });
            }}
          >
            {registerFaceID
              ? "Click here to register user face biometric"
              : "Click here to validate user by face biometric"}
          </LoadingButton>
        )}

        {showProceedBtn && (
          <div
            className="mt-20 mb-20"
            style={{
              display: "flex",
              flexDirection: "column",
              placeItems: "center",
            }}
          >
            {registerFaceID ? (
              <h3>Patient Face Biometric Updated Successfully!</h3>
            ) : (
              <>
                <h3>Patient Face Biometric Verification Successful!</h3>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  variant="contained"
                  color="primary"
                  className={`capitalize color-white`}
                  sx={{ width: "200px", mt: 2 }}
                  onClick={() => {
                    dispatch({
                      type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
                      payload: {
                        auth_status: 3,
                      },
                    });
                    if (id) {
                      dispatch(
                        onSubmitFaceId(
                          {
                            draft_claim_id: id,
                            form_step: "3",
                          },
                          id
                        )
                      );
                    } else {
                      Utils.showAlert(2, "Claim ID not found");
                    }
                  }}
                >
                  Proceed
                </LoadingButton>
              </>
            )}
          </div>
        )}
      </div>

      {showFaceCapturedPopup && (
        <CustomModal
          title={"Face Biometric Detection Result"}
          content={
            <Typography sx={{ fontSize: 22 }}>
              {imageCaptured.faceId === face_id
                ? "Face biometric matched successfully."
                : "Face biometric does not match! Please try again."}
            </Typography>
          }
          open={showFaceCapturedPopup}
          handleClose={() => {
            ws.current.disconnect();
            setShowFaceCapturedPopup(false);
            setUserStream(null);
            setIsStream(null);
            setRawImage(null);
            if (imageCaptured.faceId === face_id) {
              setShowProceedBtn(true);
            } else {
              setHideFaceIdBtn(false);
            }
          }}
          addCustomActions={true}
          actionItems={
            imageCaptured.faceId === face_id ? (
              <Button
                variant="contained"
                className={`capitalize color-white ${loading ? "on-load" : ""}`}
                sx={{ width: "140px", margin: "0px" }}
                onClick={() => {
                  ws.current.disconnect();
                  setShowFaceCapturedPopup(false);
                  setUserStream(null);
                  setIsStream(null);
                  setRawImage(null);
                  setShowProceedBtn(true);
                }}
              >
                OK
              </Button>
            ) : (
              <LoadingButton
                loading={loading || timeLeft}
                loadingPosition="start"
                variant="contained"
                color="primary"
                className={`capitalize color-white`}
                sx={{ width: "140px" }}
                onClick={() => {
                  setShowFaceCapturedPopup(false);
                  setRawImage(null);
                  connectToSocket();
                  setLoading(true);
                  ws?.current?.emit("startVideoRequest", {
                    start: 1,
                  });
                  setVideoNotShowing(true);
                  setTimeLeft(15);
                }}
                disabled={timeLeft}
              >
                Retry {timeLeft ? `(${timeLeft}s)` : ""}
              </LoadingButton>
            )
          }
        />
      )}

      {showFaceNotCapturedPopup && (
        <CustomModal
          title={"Face Biometric Detection Result"}
          content={
            <Typography sx={{ fontSize: 22 }}>
              {
                "Sorry, the face is not clear in the captured biometric image. Please make sure patient face is clearly visible in the captured image."
              }
            </Typography>
          }
          open={showFaceNotCapturedPopup}
          handleClose={() => {
            ws.current.disconnect();
            setShowFaceNotCapturedPopup(false);
            setUserStream(null);
            setRawImage(null);
            setUpdatefaceId(null);
            setHideFaceIdBtn(false);
            setOccupied(false);
          }}
          addCustomActions={true}
          actionItems={
            <LoadingButton
              loading={loading || timeLeft}
              loadingPosition="start"
              variant="contained"
              color="primary"
              className={`capitalize color-white`}
              sx={{ width: "140px" }}
              onClick={() => {
                setShowFaceNotCapturedPopup(false);
                setRawImage(null);
                connectToSocket();
                setLoading(true);
                ws?.current?.emit("startVideoRequest", {
                  start: 1,
                });
                setVideoNotShowing(true);
                setTimeLeft(15);
              }}
              disabled={timeLeft}
            >
              Retry {timeLeft ? `(${timeLeft}s)` : ""}
            </LoadingButton>
          }
        />
      )}

      {updatefaceId && (
        <CustomModal
          title={""}
          content={
            <Typography sx={{ fontSize: 22 }}>{updatefaceId?.msg}</Typography>
          }
          open={updatefaceId}
          handleClose={() => {
            setShowFaceCapturedPopup(false);
            setUserStream(null);
            setIsStream(null);
            setRawImage(null);
            setShowProceedBtn(true);
            setUpdatefaceId(null);
            setOccupied(false);
          }}
          handleButtonClick={() => {
            setShowFaceCapturedPopup(false);
            setUserStream(null);
            setIsStream(null);
            setRawImage(null);
            setShowProceedBtn(true);
            setUpdatefaceId(null);
            setOccupied(false);
          }}
        />
      )}
      {noDevice && (
        <CustomModal
          title={""}
          content={<Typography sx={{ fontSize: 22 }}>{noDeviceMsg}</Typography>}
          open={noDevice}
          handleClose={handleNoDevice}
          handleButtonClick={handleNoDevice}
        />
      )}
      {noFrUniqueId && (
        <CustomModal
          title={""}
          content={
            <Typography sx={{ fontSize: 22 }}>
              No device is associated with your account for face biometric
              authentication. Please connect with admin.
            </Typography>
          }
          open={noFrUniqueId}
          handleClose={() => {
            setNoFrUniqueId(false);
            navigate(Utils.routes.myClaims);
          }}
          handleButtonClick={() => {
            setNoFrUniqueId(false);
            navigate(Utils.routes.myClaims);
          }}
        />
      )}

      {showConfirmationPopupBeforeUpdate && (
        <CustomModal
          title={""}
          content={
            <>
              <Typography variant="body1" sx={{ fontSize: 22 }}>
                Face biometric that you captured will be linked to:
                <br />
                Card number: <b>{insuranceNumber}</b>
                <br />
                Patient name: <b>{patientName}</b>
                <br />
                Insurance company: <b>{insuranceCompanyName}</b>
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 22, mt: 2 }}>
                An OTP has been sent to the user to confirm capturing of the
                user's face biometric.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                name="auth_code"
                id="auth_code"
                label="Enter OTP"
                type="text"
                fullWidth
                variant="standard"
                onChange={
                  // (_: any, result: any) => setOtp(result.value)
                  (e: any) => setOtp(e.target.value)
                }
              />
            </>
          }
          open={showConfirmationPopupBeforeUpdate}
          handleClose={() => setShowConfirmationPopupBeforeUpdate(false)}
          addCustomActions={true}
          actionItems={
            <>
              <Button
                variant="outlined"
                className={clsx({
                  btn: true,
                  "btn-simple": true,
                  // small: true,
                  // "on-load": isResendApiLoading,
                })}
                onClick={sendOtp}
              >
                Resend OTP
              </Button>
              <Button
                variant="contained"
                className={`color-white ${isResendApiLoading ? "on-load" : ""}`}
                sx={{ width: "auto", marginLeft: "20px !important" }}
                onClick={() => verifyOtp()}
              >
                Proceed
              </Button>
            </>
          }
        />
      )}
      {/* show popup when device is occupied */}
      {isOccupied && (
        <CustomModal
          title={"Message"}
          content={
            <Typography sx={{ fontSize: 22 }}>{isOccupiedMsg}</Typography>
          }
          open={isOccupied}
          handleClose={() => {
            setShowFaceCapturedPopup(false);
            setUserStream(null);
            setIsStream(null);
            setRawImage(null);
            setHideFaceIdBtn(false);
            setOccupied(false);
            setLoading(false);
          }}
          handleButtonClick={() => {
            setShowFaceCapturedPopup(false);
            setUserStream(null);
            setIsStream(null);
            setRawImage(null);
            setHideFaceIdBtn(false);
            setOccupied(false);
            setLoading(false);
          }}
        />
      )}
    </>
  );
};
// );
export default FR;
