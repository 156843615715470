import { combineReducers } from "redux";

import {
  otpReducer,
  countryReducer,
  logoutReducer,
  //importing theme reducer
  themeReducer,
  signInReducer,
} from "../screens/login/reducer";
import {
  pharmacyCityReducer,
  createProfileDataReducer,
  getSignupCreateProfleDetailsReducer,
} from "../screens/signUp/reducer";
import {
  PatientInsuranceCompanyReducer,
  medicineDetailsReducer,
  createMedicineDetailsListReducer,
  TabModelReducer,
  ProviderDetailsReducer,
} from "../screens/myClaims/reducer";
import { globalLoaderReducer } from "./rootReducer";
import {
  AddMedicinesReducer,
  InsuranceCompanyReducer,
  InsurancePatientProfileReducer,
  SentClaimsReducer,
  MyClaimSearchMedicineDoctorHospitalReducer,
  addmidicineFormitModalReduce,
  createClaimSecondStepReducer,
  claimDetailReducer,
  TemplateClaimsReducer,
  sentClaimReducer,
  createClaimStepTwoDoctorAndHospitalAndICDDataReducer,
} from "../screens/myClaims/reducer";

import {
  MyProfilePersonalInfoReducer,
  EditMyProfilePersonalInfoReducer,
  WorkTimeReducer,
  PaymentDetailsReducer,
  SubscriptionReducer,
  SubscriptionHistoryReducer,
  SubscriptionStatusReducer,
} from "../screens/myProfile/reducer";

import { DrugFormularyReducer } from "../screens/drugFormulary/reducer";
import {
  DoctorPrescriptionsReducer,
  createClaimPrescriptionReducer,
  createMedicineDetailsListPrescriptionReducer,
  addmidicineFormikPrescriptionReducer,
  createDoctorClaimSecondStepReducer,
} from "../screens/doctorPrescriptions/reducer";

import { DashBoardReducer } from "../screens/dashboard/reducer";

import { TermsReducer } from "../screens/termsCondition/reducer";
import { insurancePaymentReducer } from "../screens/payment/reducer";
import { websiteManagementReducer } from "../screens/websitManagement/reducer";
import { HospitalPrescriptionsReducer } from "../screens/hospitalPrescription/reducer";

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  signInReducer,
  countryReducer,
  globalLoaderReducer,
  logoutReducer,
  otpReducer,
  pharmacyCityReducer,
  createProfileDataReducer,
  //combined theme reducer to root reducer
  themeReducer,
  AddMedicinesReducer,
  InsuranceCompanyReducer,
  InsurancePatientProfileReducer,
  SentClaimsReducer,
  PatientInsuranceCompanyReducer,
  medicineDetailsReducer,
  createMedicineDetailsListReducer,
  MyClaimSearchMedicineDoctorHospitalReducer,
  addmidicineFormitModalReduce,
  createClaimSecondStepReducer,
  claimDetailReducer,
  TemplateClaimsReducer,
  MyProfilePersonalInfoReducer,
  EditMyProfilePersonalInfoReducer,
  sentClaimReducer,
  DoctorPrescriptionsReducer,
  HospitalPrescriptionsReducer,
  DashBoardReducer,
  DrugFormularyReducer,
  WorkTimeReducer,
  PaymentDetailsReducer,
  createClaimPrescriptionReducer,
  createMedicineDetailsListPrescriptionReducer,
  addmidicineFormikPrescriptionReducer,
  createDoctorClaimSecondStepReducer,
  getSignupCreateProfleDetailsReducer,
  TabModelReducer,
  TermsReducer,
  createClaimStepTwoDoctorAndHospitalAndICDDataReducer,
  insurancePaymentReducer,
  websiteManagementReducer,
  ProviderDetailsReducer,
  SubscriptionReducer,
  SubscriptionHistoryReducer,
  SubscriptionStatusReducer,
});

export default rootReducer;
