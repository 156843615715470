import { useState, useEffect, useRef } from "react";
import { Paper, Button, Backdrop, CircularProgress } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputBase from "@mui/material/InputBase";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, createStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
import "../../styles/table.css";
import "../../styles/listing-filters.css";
import { getInsuranceCompany, getSubscriptionStatus } from "../myClaims/action";
import { getDoctorPrescriptions } from "./action";
import FilterComponent from "./filter";
import TableComponent from "./table";

// import { useNavigate } from "react-router";
import Utils from "../../utils";
import { ReducersModal } from "../../modal";

const useStyles = makeStyles(() =>
  createStyles({
    leftSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
      width: "44%",
    },
    rightSideFilters: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginBottom: "10px",
      marginLeft: "10px",
      width: "54.9%",
    },
    customContentStyle: {
      width: "100%",
      maxWidth: "none",
    },
    listingFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      marginBottom: "10px",
    },
    backdrop: () => ({
      zIndex: 99999999999,
      color: "#fff",
    }),
  })
);

function DoctorPrescriptions() {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFiletr] = useState(false);

  const { isLoading } = useSelector((state: ReducersModal) => state.globalLoaderReducer);

  const loader = () => {
    return (
      <Backdrop
        className={classes.backdrop}
        open={isLoading ? isLoading : false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };


  const handleFilterClick = () => {
    setFiletr(!filter);
  };

  const debounceCount = useRef(0);
  const api = () =>
    new Promise((res) => {
      setTimeout(() => {
        res(1);
      }, 500);
    });

  const handleSearchChange = (event: any) => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        search: event.target.value,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    debounceCount.current += 1;
    const localDebounceCount = debounceCount.current;
    api().then((d) => {
      if (localDebounceCount !== debounceCount.current) {
        return;
      }
      dispatch(getDoctorPrescriptions());
    });
  };
  const handleSearchEmpty = () => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        search: "",
      },
    });
    setSearchValue("");
    dispatch(getDoctorPrescriptions());
  };

  const toggleArrow = (filter: Boolean) => {
    return filter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
  };
  useEffect(() => {
    // dispatch(getDoctorPrescriptions());
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        showVerification: false,
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
      },
    });

    dispatch(getInsuranceCompany());
    dispatch(getSubscriptionStatus());
  }, [dispatch]);

  const { data, patientInsurance } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );

  return (
    <>
      {loader()}
      <div className="page-doctor-prescriptions bg-white">
        <div className="listing-filter">
          <div className={classes.leftSideFilters}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 500,
                boxShadow: "0px 0px 2px 1px rgba(0,0,0,0.2)",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search by Doctor Name / Patient Name / Patient Phone No"
                onChange={(e: any) => {
                  handleSearchChange(e);
                  setSearchValue(e.target.value);
                }}
                value={searchValue}
              />
              {searchValue.length > 0 ? (
                <Button>
                  <ClearIcon onClick={handleSearchEmpty} />
                </Button>
              ) : (
                ""
              )}
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className="right-side-filters">
            <div className="dropdown-cover">
              <Button
                onClick={handleFilterClick}
                variant="outlined"
                size="large"
                sx={{
                  maxWidth: "180px",
                  minWidth: "80px",
                  bgcolor: "var(--white)",
                }}
              >
                <FilterListIcon />
                &nbsp;
                <span>Filters</span>&nbsp;
                {toggleArrow(filter)}
              </Button>
            </div>
          </div>
        </div>
        {filter ? (
          <FilterComponent data={data} patientInsurance={patientInsurance} />
        ) : (
          " "
        )}

        <div className="table-cover">
          <TableComponent />
        </div>
      </div>
    </>  
  );
}

export default DoctorPrescriptions;
