import React, { useEffect } from "react";
import { AppBar, Box, Toolbar, Theme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { createStyles, makeStyles } from "@mui/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Utils from "../../utils";
import "./privateHeader.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMyPersonalProfileInfo } from "../../screens/myProfile/action";
import moment from "moment";
import { isSubscriptionActive } from "../../utils/commonFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      "&.MuiAppBar-root": {
        boxShadow: "none",
      },
    },
    toolbar: {
      "&.MuiToolbar-root": {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: theme.spacing(0.5),
      },
    },
    imgDiv: {
      "&.MuiTypography-root": {
        width: theme.spacing(6.5),
      },
    },
    img: {
      width: "100%",
      height: "auto",
    },
    linkDiv: {},
    link: {
      font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
        1.6
      )} Roboto, sans-serif`,
      color: "var(--white)",
      margin: theme.spacing(0, 1),
    },
    linkInPhone: {
      color: "var(--black)",
    },
    active: {
      backgroundColor: "#fff",
      borderTopLeftRadius: "11px 9px",
      borderTopRightRadius: "11px 9px",
      height: "56px",
      color: "var(--light-green) !important",
      font: `normal ${theme.typography.fontWeightMedium} ${theme.spacing(
        1.6
      )} Roboto, sans-serif`,
    },
    linkInPhoneActive: {
      // color: "var(--light-green) !important",
    },
  })
);

const PrivateHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const pathname = useLocation().pathname;
  const params = useParams();
  const [subscriptionStausDetails, setSubscriptionStausDetails]: any =
    React.useState([]);

  const {
    avatar,
    pharmacy_name,
    license_of_pharmacy,
    pin_certificate,
    business_permit,
    practice_certificate,
    cr_12,
    tax_compliance,
    business_permit_expiry,
    license_of_pharmacy_expiry,
    practice_certificate_expiry,
    tax_compliance_expiry,
    is_branch_pharmacy,
    linked_hospital_id,
  } = useSelector((state: any) => state.MyProfilePersonalInfoReducer);

  const { subscriptionDetails } = useSelector(
    (state: any) => state.SubscriptionStatusReducer
  );
  

  useEffect(() => {
    if (
      (subscriptionDetails.isSubscriptionActive === false ||
        subscriptionDetails.isSubscriptionActive != 1) &&
      subscriptionDetails?.length > 0
    ) {
      let details: any = {
        subscriptionErrorMessage:
          "Your Subscription is expired. You are not able to create Preauth and Claims in our system. Please ",
        postsubscriptionErrorMessage: " to activate your subscription.",
      };
      dispatch({
        type: Utils.ActionName.SUBSCRIPTION_STATUS,
        payload: {
          subscriptionErrorMessage: details,
        },
      });

      setSubscriptionStausDetails(details);
    } else {
      const isSubscriptionDetails: any =
        isSubscriptionActive(subscriptionDetails);
      dispatch({
        type: Utils.ActionName.SUBSCRIPTION_STATUS,
        payload: {
          subscriptionErrorMessage: isSubscriptionDetails,
        },
      });
      setSubscriptionStausDetails(isSubscriptionDetails);
    }
  }, [subscriptionDetails]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  var todayDate = moment().format("YYYY MM DD");
  // useEffect(() => {
  //   dispatch(getMyPersonalProfileInfo());
  // }, [dispatch]);

  const handleClear = (to: any) => {
    dispatch({
      type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        showVerification: false,
        limit: 10,
        offset: 0,
        page: 1,
      },
    });

    dispatch({
      type: Utils.ActionName.DRUG_FORMULARY,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    dispatch({
      type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    dispatch({
      type: Utils.ActionName.SENT_CLAIMS,
      payload: {
        claim_start_date: "",
        claim_end_date: "",
        insurance_company_id: "",
        status: "",
        search: "",
        limit: 10,
        offset: 0,
        page: 1,
      },
    });
    navigate(to);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className={classes.toolbar}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", md: "flex" } }}
            className={classes.imgDiv}
          >
            <Link to={Utils.routes.dashboard}>
              <img
                className={classes.img}
                src={Utils.images.LogoWhite}
                alt="logo"
              />
            </Link>
          </Typography>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <div
                  className={`${classes.link} ${classes.linkInPhone}`}
                  // to={Utils.routes.dashboard}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Dashboard
                </div>
              </MenuItem> */}
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.myClaims}
                >
                  My Claims
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.doctorPrescriptions}
                >
                  Doctor Prescriptions
                </Link>
              </MenuItem>
              {linked_hospital_id && (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    className={`${classes.link} ${classes.linkInPhone}`}
                    to={Utils.routes.hospitalPrescriptions}
                  >
                    Hospital Prescriptions
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.drugFormulary}
                >
                  Drug Formulary
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.parentCompany}
                >
                  Payments
                </Link>
              </MenuItem>
              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.websiteManagement}
                >
                  Website Management
                </Link>
              </MenuItem> */}
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.registerFaceBiometric}
                >
                  Register Face Biometric
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  className={`${classes.link} ${classes.linkInPhone}`}
                  to={Utils.routes.exclusions}
                >
                  Exclusions
                </Link>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "flex", md: "none" } }}
            className={classes.imgDiv}
          >
            <img
              className={classes.img}
              src={Utils.images.LogoWhite}
              alt="logo"
            />
          </Typography>
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className={classes.linkDiv}
          >
            <div className="menu-options-list">
              {/* <div
                className={`single-menu ${
                  [Utils.routes.dashboard].includes(pathname) ? "active" : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.dashboard].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  to={Utils.routes.dashboard}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Dashboard
                </Link>
              </div> */}
              <div
                className={`single-menu ${
                  [Utils.routes.myClaims, Utils.routes.createClaim].includes(
                    pathname
                  )
                    ? "active"
                    : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.myClaims, Utils.routes.createClaim].includes(
                      pathname
                    )
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  } `}
                  to={Utils.routes.myClaims}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  My Claims
                </Link>
              </div>
              <div
                className={`single-menu ${
                  [
                    Utils.routes.doctorPrescriptions,
                    `${Utils.routes.createClaimPrescription}/${params?.draftedClaimId}`,
                  ].includes(pathname)
                    ? "active"
                    : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.doctorPrescriptions].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  to={Utils.routes.doctorPrescriptions}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Doctor Prescriptions
                </Link>
              </div>
              {linked_hospital_id && (
                <div
                  className={`single-menu ${
                    [
                      Utils.routes.hospitalPrescriptions,
                      `${Utils.routes.createClaimPrescriptionForHospital}/${params?.draftedClaimId}`,
                    ].includes(pathname)
                      ? "active"
                      : ""
                  } `}
                >
                  <Link
                    className={`${classes.link} ${
                      [Utils.routes.hospitalPrescriptions].includes(pathname)
                        ? classes.linkInPhone
                        : classes.linkInPhoneActive
                    }`}
                    to={Utils.routes.hospitalPrescriptions}
                    onClick={() => handleClear(Utils.routes.dashboard)}
                  >
                    Hospital Prescriptions
                  </Link>
                </div>
              )}
              <div
                className={`single-menu ${
                  [Utils.routes.drugFormulary].includes(pathname)
                    ? "active"
                    : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.drugFormulary].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  to={Utils.routes.drugFormulary}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Drug Formulary
                </Link>
              </div>
              {+is_branch_pharmacy === 0 && (
                <div
                  className={`single-menu ${
                    [
                      Utils.routes.parentCompany,
                      Utils.routes.reviewInsPay,
                    ].includes(pathname)
                      ? "active"
                      : ""
                  } `}
                >
                  <Link
                    className={`${classes.link} ${
                      [
                        Utils.routes.parentCompany,
                        Utils.routes.reviewInsPay,
                      ].includes(pathname)
                        ? classes.linkInPhone
                        : classes.linkInPhoneActive
                    }`}
                    to={Utils.routes.parentCompany}
                    onClick={() => handleClear(Utils.routes.dashboard)}
                  >
                    Payments
                  </Link>
                </div>
              )}
              {/* <div
                className={`single-menu ${
                  [Utils.routes.websiteManagement].includes(pathname)
                    ? "active"
                    : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.websiteManagement].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  to={Utils.routes.websiteManagement}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Website Management
                </Link>
              </div> */}
              <div
                className={`single-menu ${
                  [Utils.routes.registerFaceBiometric].includes(pathname)
                    ? "active"
                    : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.registerFaceBiometric].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  to={Utils.routes.registerFaceBiometric}
                  onClick={() => handleClear(Utils.routes.dashboard)}
                >
                  Register Face Biometric
                </Link>
              </div>
              <div
                className={`single-menu ${
                  [Utils.routes.exclusions].includes(pathname) ? "active" : ""
                } `}
              >
                <Link
                  className={`${classes.link} ${
                    [Utils.routes.exclusions].includes(pathname)
                      ? classes.linkInPhone
                      : classes.linkInPhoneActive
                  }`}
                  style={{ color: "#FF0000" }}
                  to={Utils.routes.exclusions}
                  onClick={() => handleClear(Utils.routes.exclusions)}
                >
                  Exclusions
                </Link>
              </div>
            </div>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={pharmacy_name}>
                <IconButton sx={{ p: 0 }}>
                  <Link
                    to={Utils.routes.myProfile}
                    style={{ position: "relative" }}
                  >
                    <Avatar
                      alt={pharmacy_name}
                      src={Utils.constants.API_URL + avatar}
                    />
                    {!license_of_pharmacy ||
                    !pin_certificate ||
                    !business_permit ||
                    !practice_certificate ||
                    !cr_12 ||
                    !tax_compliance ||
                    moment(todayDate).isSameOrAfter(
                      practice_certificate_expiry
                    ) ||
                    moment(todayDate).isSameOrAfter(
                      license_of_pharmacy_expiry
                    ) ||
                    moment(todayDate).isSameOrAfter(business_permit_expiry) ||
                    moment(todayDate).isSameOrAfter(tax_compliance_expiry) ? (
                      <span className="dotProfileHeader"></span>
                    ) : (
                      ""
                    )}
                  </Link>
                </IconButton>
              </Tooltip>
            </Box>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, color: "#fff" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <ExpandMoreIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  navigate(Utils.routes.dashboard);
                }}
              >
                <ListItemIcon>
                  <DashboardIcon fontSize="small" />
                </ListItemIcon>
                Dashboard
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(Utils.routes.websiteManagement);
                }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Website Management
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(Utils.routes.myProfile);
                }}
              >
                <ListItemIcon>
                  <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                My profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(Utils.routes.manageSubscription);
                }}
              >
                <ListItemIcon>
                  <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                Manage Subscription
              </MenuItem>
              <MenuItem
                onClick={() => {
                  Utils.constants.removeSession();
                  navigate("/");
                }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {subscriptionStausDetails?.subscriptionErrorMessage && (
        <div className="subscription-error-cover">
          <div
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px 10px",
              // fontWeight: "bold",
              color: "white",
              background: "#d71111",
            }}
          >
            {subscriptionStausDetails?.subscriptionErrorMessage}
            {pathname.includes("my-profile") ? (
              <>
                <a
                  href="/my-profile/msubcription"
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "17px",
                  }}
                >
                  Pay Now
                </a>
              </>
            ) : (
              <>
                <Link
                  to={Utils.routes.manageSubscription}
                  style={{
                    color: "white",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    cursor: "pointer",
                    fontSize: "17px",
                  }}
                >
                  Pay Now
                </Link>
              </>
            )}

            {subscriptionStausDetails?.postsubscriptionErrorMessage}
          </div>
        </div>
      )}
    </AppBar>
  );
};
export default PrivateHeader;
