import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { ReducersModal } from "../../../modal";
// import {getInsuredMember} from "../../../screens/myClaims/action";
import {
  getSentClaims,
  getTemplateClaims,
  providerNotInRangeNotification,
} from "../../../screens/myClaims/action";
import { getInscurancePaymentClaims } from "../../../screens/payment/action";
import { getDrugFormulary } from "../../../screens/drugFormulary/action";
import { getDoctorPrescriptions } from "../../../screens/doctorPrescriptions/action";
import Utils from "../../../utils";
import { getHospitalPrescriptions } from "../../../screens/hospitalPrescription/action";
import {
  claimCreateErrMsg,
  getAddress,
  getLocation,
  userDeniedLocationErrMsg,
  validateRadiusRange,
} from "../../../utils/commonFunctions";
import { getSubscriptionHistory } from "../../../screens/myProfile/action";
import { useNavigate } from "react-router-dom";
const CustomSelect = ({ name, options, ...otherProps }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state: ReducersModal) => state.InsuranceCompanyReducer
  );
  const { data: ProviderDetails } = useSelector(
    (state: ReducersModal) => state.ProviderDetailsReducer
  );

  const { insurance_member_dependents, entityId } = useSelector(
    (state: ReducersModal) => state.InsurancePatientProfileReducer
  );

  const { subscriptionDetails } = useSelector(
    (state: ReducersModal) => state.SubscriptionStatusReducer
  );

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt: any) => {
    const { value } = evt.target;
    if (name === "patient") {
      dispatch({
        type: Utils.ActionName.SENT_CLAIMS,
        payload: {
          insurance_company_id: value,
        },
      });
      dispatch(getSentClaims());
    }
    if (name === "subscription_status") {
      dispatch({
        type: Utils.ActionName.SUBSCRIPTION_HISTORY,
        payload: {
          subscription_status: value,
        },
      });
      dispatch(getSubscriptionHistory());
    }

    if (name == "payment_status") {
      dispatch({
        type: Utils.ActionName.SUBSCRIPTION_HISTORY,
        payload: {
          payment_status: value,
        },
      });
      dispatch(getSubscriptionHistory());
    }
    if (name === "patient_payment") {
      dispatch({
        type: Utils.ActionName.INSURANCE_PAYMENT,
        payload: {
          branch_pharmacy_id: value,
        },
      });
      dispatch(getInscurancePaymentClaims());
    }
    if (name === "patient_template") {
      dispatch({
        type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
        payload: {
          insurance_company_id: value,
        },
      });
      dispatch(getTemplateClaims());
    }
    if (name === "patient_drug") {
      dispatch({
        type: Utils.ActionName.DRUG_FORMULARY,
        payload: {
          insurance_company_id: value,
        },
      });
      dispatch(getDrugFormulary());
    }
    if (name === "patient_prescription") {
      dispatch({
        type: Utils.ActionName.DOCTOR_PRESCRIPTIONS,
        payload: {
          insurance_company_id: value,
        },
      });
      dispatch(getDoctorPrescriptions());
    }
    if (name === "hospital_prescription") {
      dispatch({
        type: Utils.ActionName.HOSPITAL_PRESCRIPTIONS,
        payload: {
          insurance_company_id: value,
        },
      });
      dispatch(getHospitalPrescriptions());
    }
    if (name === "status") {
      dispatch({
        type: Utils.ActionName.SENT_CLAIMS,
        payload: {
          status: value,
        },
      });
      dispatch(getSentClaims());
    }
    if (name === "status_payment") {
      dispatch({
        type: Utils.ActionName.INSURANCE_PAYMENT,
        payload: {
          status: value,
        },
      });
      dispatch(getInscurancePaymentClaims());
    }
    if (name === "status_template") {
      dispatch({
        type: Utils.ActionName.GET_TEMPLATE_CLAIMS,
        payload: {
          status: value,
        },
      });
      dispatch(getTemplateClaims());
    }
    if (name === "countryCode") {
      dispatch({
        type: Utils.ActionName.SIGN_IN,
        payload: { countryCode: value },
      });
      localStorage.setItem("countryCode", value);
    } else if (name === "patientInsurance") {
      if (subscriptionDetails.isSubscriptionActive != 1) {
        navigate(Utils.routes.manageSubscription);
      }
      getLocation(ProviderDetails?.is_geoTag_skipped)
        .then((res: any) => {
          validateRadiusRange(
            res.coords.latitude,
            res.coords.longitude,
            ProviderDetails.provider_lat,
            ProviderDetails.provider_lng,
            ProviderDetails.provider_radius_range,
            res.coords.accuracy,
            ProviderDetails.is_geoTag_skipped
          )
            .then((result: any) => {
              dispatch({
                type: Utils.ActionName.GET_INSURANCE_COMPANY,
                payload: { patientInsurance: value },
              });
              dispatch({
                type: Utils.ActionName.GET_PROVIDER_DETAILS,
                payload: {
                  data: {
                    ...ProviderDetails,
                    isProviderInRange: true,
                    currentLat: res.coords.latitude,
                    currentLng: res.coords.longitude,
                  },
                },
              });
            })
            .catch((error) => {
              getAddress(res.coords.latitude, res.coords.longitude).then(
                (address: any) => {
                  const params = {
                    lat: res.coords.latitude,
                    lng: res.coords.longitude,
                    screen: "Claim",
                    action: "Create",
                    address: address,
                    portal: "Chemist",
                  };
                  dispatch(providerNotInRangeNotification(params));
                }
              );
              dispatch({
                type: Utils.ActionName.GET_PROVIDER_DETAILS,
                payload: {
                  data: {
                    ...ProviderDetails,
                    geoTagErrorPopup: true,
                    isLocationEnabled: true,
                  },
                },
              });
              return false;
            });

          dispatch({
            type: Utils.ActionName.GET_PROVIDER_DETAILS,
            payload: {
              data: {
                ...ProviderDetails,
                currentLat: res.latitude,
                currentLong: res.longitude,
                locationAccuracy: res.accuracy,
              },
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: Utils.ActionName.GET_PROVIDER_DETAILS,
            payload: {
              data: {
                ...ProviderDetails,
                isLocationEnabled: false,
                geoTagErrorPopup: true,
              },
            },
          });

          //Utils.showAlert(2, userDeniedLocationErrMsg)
          return false;
        });

      const insuranceData: any = data.find((a: any) => a.id === value);

      let cardListArray = [];

      if (insuranceData.is_insurance_card === "1") {
        cardListArray.push({
          id: 2,
          name:
            insuranceData.id === "13"
              ? "Search with LCT Member No."
              : "Search with Member No.",
          is_insurance_card: insuranceData.is_insurance_card,
          image: insuranceData.insurance_card_image,
        });
      }
      if (insuranceData.is_smart_card === "1") {
        cardListArray.push({
          id: 1,
          name: "Search with Smart Card",
          is_smart_card: insuranceData.is_smart_card,
          image: insuranceData.smart_card_image,
        });
      }
      if (insuranceData.is_doc_required === "0") {
        dispatch({
          type: Utils.ActionName.GET_PATIENT_DATA,
          payload: {
            claimSteps: [
              "Patient Information",
              "Transcribe Prescription",
              "Patient Verification",
            ],
          },
        });
      }
      if (insuranceData.is_doc_required === "1") {
        dispatch({
          type: Utils.ActionName.GET_PATIENT_DATA,
          payload: {
            claimSteps: [
              "Patient Information",
              "Transcribe Prescription",
              "Patient Verification",
              "Upload Prescription",
            ],
          },
        });
      }

      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          cardListArray: cardListArray,
          authType: JSON.parse(insuranceData.auth_type),
          insuranceCardType: "",
          entityId: "",
          patientInsuranceMemberDataList: "",
          showSearchMemberNo: false,
          insuranceData,
          showAuthenticationType:
            JSON.parse(insuranceData.auth_type).length === 1 ? false : true,
          showOtpVerification: "",
          phoneNumber: "",
          search: "",
          insuranceMemberOptionSelect: false,
          patientFamilyMemberData: [],
          authTypeSelected: "",
          checked: false,
          insurance_member_dependents: [],

          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            showOtpVerification: "0",
          }),
          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            patientInsuranceMember: [],
          }),
          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            patientInsuranceMemberDataList: [],
          }),
          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            termsConditionChecked: false,
          }),
          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            showSearchMemberNo: true,
          }),
          ...(JSON.parse(insuranceData.auth_type).length === 1 && {
            insuranceCardType: "1",
          }),
        },
      });

      // ;
      // dispatch(getInsuredMember(value));
    } else if (name === "patientCountryCode") {
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          patientCountryCode: value,
        },
      });
    } else if (name === "familyMemberPhoneNumber") {
      var phone = value;
      if (value !== null && value !== undefined) {
        phone = value.slice(3, 12);
      }
      var finalId = entityId;
      if (
        insurance_member_dependents !== null &&
        insurance_member_dependents !== undefined &&
        insurance_member_dependents.length > 0
      ) {
        finalId = insurance_member_dependents.find(
          (a: any) => a.user_id === value
        );
      }

      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          familyMemberPhoneNumber: finalId.login_phone.slice(3, 12),
          // phoneNumber:phone,
          checked: false,
          finalEntityId: finalId.entity_id,
          userPrincipalId: value,
        },
      });
    } else if (name === "userSubBenefits") {
      dispatch({
        type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
        payload: {
          subbenefit_name: value,
        },
      });
    }
    // else if (name === "userBenefits") {
    //   dispatch({
    //     type: Utils.ActionName.INSURANCE_PATIENT_PROFILE,
    //     payload: {
    //       userSubBenefit:value,

    //     },
    //   });
    // }
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  return <TextField {...configSelect}>{options}</TextField>;
};

export default CustomSelect;
